import * as CryptoJS from 'crypto-js'
import { type UserType } from '../utils/types'
import audienceService from './audienceService'

const mockUserData: UserType = {
  audience: {
    id: 2,
    name: 'BCA_B',
    frequency: 0,
    minOrder: 5,
    maxOrder: 20
  },
  id: '00000000',
  accountNumber: 'FRX0000002',
  contactId: '0032500000zlddzAAA',
  touchPoint: '0012500000pkPpYAAU',
  accoundEmail: 'sghaier.saif.eddine@gmail.com',
  accoundLastName: 'accoundLastName',
  accoundFirstName: 'accoundFirstName',
  shopName: 'shopName',
  shopAddress: 'shopAddress'
}

export const getInitAppParam = async (
  encodedQueryParamString: string | null
): Promise<UserType> => {
  try {
    const safeEncodedString = encodedQueryParamString
      ? encodedQueryParamString.replace(/ /g, '+')
      : ''
    const decryptionKey = CryptoJS.enc.Utf8.parse(
      process.env.REACT_APP_KEY_WA || ''
    )
    const decryptionIV = CryptoJS.enc.Utf8.parse(
      process.env.REACT_APP_IV_WA || ''
    )

    const decryptedData = CryptoJS.AES.decrypt(
      safeEncodedString,
      decryptionKey,
      { iv: decryptionIV }
    ).toString(CryptoJS.enc.Utf8)

    const decodedQueryParam = JSON.parse(decryptedData)

    const userAudience = await audienceService.getAudienceByName(
      (decodedQueryParam?.User?.Contact?.Account?.DTE_FlexParameter3__c || '')
        .split(';')
        .find((audienceName: string) =>
          audienceName.startsWith('BCA')
        ) as string
    )

    const formattedAddress =
      decodedQueryParam?.User?.Contact?.Account?.BillingAddress.street +
      ', ' +
      decodedQueryParam?.User?.Contact?.Account?.BillingAddress.city +
      ' ' +
      decodedQueryParam?.User?.Contact?.Account?.BillingAddress.postalCode

    return {
      audience: userAudience || mockUserData.audience,
      id: decodedQueryParam?.User?.Id || mockUserData.id,
      accountNumber:
        decodedQueryParam?.User?.Contact?.Account?.DTE_AccountNumber__c ||
        mockUserData.accountNumber,
      contactId: decodedQueryParam?.User?.ContactId || mockUserData.contactId,
      touchPoint:
        decodedQueryParam?.User?.Contact?.AccountId || mockUserData.touchPoint,
      accoundEmail: decodedQueryParam?.User?.Email || '',
      accoundLastName: decodedQueryParam?.User?.LastName || '',
      accoundFirstName: '',
      shopName: decodedQueryParam?.User?.Contact?.Account?.Name || '',
      shopAddress: formattedAddress || ''
    }
  } catch (error) {
    console.error('Error processing the iframe config:', error)
    return mockUserData
  }
}

export default { getInitAppParam }
