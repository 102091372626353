import React, { type ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'
import AdminPanelSidebar from '../../components/utils/adminPanelSidebar/AdminPanelSidebar'
import './admin-panel.css'
import Audiences from '../../components/audiences/Audiences'
import Packages from '../../components/packages/Packages'
import Products from '../../components/products/Products'
import ImagesLibrary from '../../components/imagesLibrary/ImagesLibrary'

const AdminPanel = (): ReactElement => (
  <div className='admin-container'>
    <AdminPanelSidebar />

    <div className='admin-content'>
      <Routes>
        <Route element={<Audiences />} path='audiences' />
        <Route element={<Products />} path='products' />
        <Route element={<Packages />} path='packages' />
        <Route element={<ImagesLibrary />} path='images' />
      </Routes>
    </div>
  </div>
)

export default AdminPanel
