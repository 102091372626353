import axios from 'axios'
import { type ProductType } from '../utils/types'

const apiBaseUrl = process.env.REACT_APP_API_URL

const createProduct = async (newProduct: any): Promise<ProductType> => {
  try {
    const response = await axios.post(`${apiBaseUrl}/products`, newProduct)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getAllProducts = async (): Promise<ProductType[]> => {
  try {
    const response = await axios.get<ProductType[]>(`${apiBaseUrl}/products`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const deleteProduct = async (id: number): Promise<void> => {
  try {
    const response = await axios.delete(`${apiBaseUrl}/products/${id}`)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const getProductsByAudience = async (
  id: number,
  page: number,
  limit: number = 9999
): Promise<{
  totalItems: number
  products: ProductType[]
  totalPages: number
  currentPage: number
}> => {
  try {
    const response = await axios.get(
      `${apiBaseUrl}/products/product-by-audience/${id}`,
      {
        params: { page, limit }
      }
    )
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const updateProduct = async (id: number, newObject: any): Promise<void> => {
  try {
    const response = await axios.put(`${apiBaseUrl}/products/${id}`, newObject)
    return response.data
  } catch (error) {
    throw new Error('Network error')
  }
}

const importProducts = async (file: FormData, imageUrls: any): Promise<void> => {
  try {
    file.append('imageUrls', JSON.stringify(imageUrls))
    const response = await axios.post(`${apiBaseUrl}/products/import`, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    console.log('File uploaded successfully', response.data)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data) {
      const errorMessages = error.response.data.errors
      if (Array.isArray(errorMessages)) {
        const errorMessageString = errorMessages.join('\n')
        throw new Error(errorMessageString)
      } else {
        throw new Error(error.response.data.message as string)
      }
    } else {
      throw new Error(
        "Une erreur est survenue lors de l'importation des produits."
      )
    }
  }
}

const downloadExportedProducts = async (): Promise<void> => {
  try {
    const response = await axios.get(`${apiBaseUrl}/products/export`, {
      responseType: 'blob'
    })

    const file = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })

    const fileURL = URL.createObjectURL(file)

    const link = document.createElement('a')
    link.href = fileURL
    link.setAttribute('download', 'fichier_produits.xlsx')
    document.body.appendChild(link)
    link.click()

    if (link.parentNode) {
      link.parentNode.removeChild(link)
    }
    URL.revokeObjectURL(fileURL)
  } catch (error) {
    console.error('Failed to download the file:', error)
  }
}

const disableProduct = async (
  id: number,
  enabled: boolean
): Promise<Partial<ProductType>> => {
  try {
    return await axios.put(`${apiBaseUrl}/products/disable/${id}`, { enabled })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `Une erreur est survenue lors de la désactivation du produit ${id}:`,
      error
    )
    return {}
  }
}

const updateProductRank = async (
  productId: number,
  updatedData: number,
  audienceId: number
): Promise<number> => {
  try {
    // Make a PATCH request to the API to update the product
    const response = await axios.patch(
      `${apiBaseUrl}/products/${productId}/rank`,
      { newRank: updatedData, audienceId: audienceId }
    )
    return response.data.newRank // Return the updated product data
  } catch (error) {
    console.error('Error updating product:', error)
    throw error // Propagate the error so it can be handled by the caller
  }
}

const bulkDeleteProducts = async (productIds: number[]): Promise<void> => {
  try {
    await axios.delete(`${apiBaseUrl}/products/bulk-delete`, {
      data: { productIds },
      headers: { 'Content-Type': 'application/json' }
    })
  } catch (error) {
    console.error('Error deleting products:', error)
    throw new Error('Error occurred while attempting bulk delete.')
  }
}

export default {
  createProduct,
  getAllProducts,
  deleteProduct,
  updateProduct,
  importProducts,
  getProductsByAudience,
  downloadExportedProducts,
  disableProduct,
  updateProductRank,
  bulkDeleteProducts
}
